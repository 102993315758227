#layout.en{
  .basic-drop-down {
    .info {
      .d-name {
        font-size: 16px;
      }
    }

  }

}