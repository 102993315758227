$midnight: #100225;
$main: #f3bd41;
$fontFamily: 'Montserrat', sans-serif;
$navLinkColor: rgba(255, 255, 255, 0.8);
$yellow: #f3bd41;
$black: #333333;
$brownGrey: #9b9b9b;
$aquaBlue: #00d6e6;
$blue: #049dd9;
$blueLight: #09caed;
$new: #10427a;
$light-grey-form-bg: #f6f6f6;
