@import 'variables';

@media (min-width: 1199px) {
	#search-button-container {
		padding-left: 5px;
		padding-right: 5px;
	}
}
@media (min-width: 992px) {
	#contact-us {
		.btn {
			max-width: 256px;
		}
	}
	#search-button {
		&.price-alert-handler-checked {
			font-size: 14px !important;
		}
	}
}

@media (min-width: 1200px) {
	#family-type-options {
		.room {
			display: flex;
		}
	}

	.rtl {
		.confirmation-switch {
			margin-left: 180px;
		}
	}
}

@media (min-width: 1400px) {
	.rtl {
		.confirmation-switch {
			margin-left: 215px;
		}
	}
}

@media (max-width: 442px) {
	#passthru-p3 {
		.title {
			font-size: 25px;
		}
	}
	#cookie-notice {
		.description {
			font-size: 13px !important;
			line-height: 1.5 !important;
		}

		img {
			width: 16px !important;
			margin: 0 5px;
		}
	}
}

@media (min-width: 1199px) {
	.navbar-parent {
		padding-left: 0;
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199px) {
	#parent-filter {
		margin-top: 0;
	}
	#image-curve {
		margin-top: 30px;
		p {
			position: relative;
			z-index: 2;
		}
	}
	#kayak-compare-filter-form {
		.input-items {
			.form-control,
			.input-control {
				background: #eee;
			}
		}
	}
	.input-items {
		margin: 6px 0;

		label {
			margin: 4px 0;
		}
		.form-control,
		.input-control {
			background: #fff;
		}
	}
	#cookie-notice .description,
	.cookie-confirm-btn {
		font-size: 13px;
	}
	.hotels-filter-form {
		margin-top: 40px;
	}
	#newsletter-form {
		h4,
		h5 {
			font-size: 24px;
		}
	}
	.input-group {
		border-radius: 4px !important;
	}
	.flex-one-desktop {
		display: unset !important;
	}
	#auth-navbar-nav .nav-link .brief {
		display: none;
	}
	.sign-up-button {
		font-family: $fontFamily, sans-serif;
		font-size: 18px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #fff;
	}
	#search-button-container {
		#search-button {
			min-height: 50px;
			font-size: 22px;
		}
	}

	#slogan-container {
		padding: 50px 0;

		&:before {
			display: none;
		}
	}

	#filter-form {
		.inline-inputs {
			.input {
				width: 100%;
			}
		}
	}
	.input-control:not(.filled) i {
		color: #6d6d6d;
	}
	.basic-drop-down {
		margin-right: 0;

		.body {
			max-width: unset !important;
		}
	}
	#companies {
		margin-top: 25px;
		margin-bottom: 25px;
	}
	#search-button {
		box-shadow: unset !important;
	}
	#compare-area {
		#compare-company-result-header {
			.logo {
				width: 150px;
			}
		}
		.item-info {
			.item-name {
				font-size: 14px;
				line-height: 16px;
				margin-top: 8px;
			}
			.item-value {
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 8px;
			}
		}
		.location-info {
			display: flex;
			align-items: center;
			margin-right: 24px;

			.location-switcher {
				margin: 2px 18px;
				cursor: pointer;

				i {
					font-weight: bold;
				}
			}

			.location-name {
				font-size: 24px;
				line-height: 28px;
				color: white;
			}
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
	#search-button-container {
		margin-top: 10px;
	}
	#search-button-container #search-button {
		font-size: 16px;
	}
	#newsletter-form {
		text-align: center;
	}
	.input-items {
		.form-control,
		.input-control {
			background: #fff;
		}
	}
	footer #footer-links li,
	footer p {
		font-size: 18px;
	}

	#slogan-container .body .slogan {
		font-size: 30px;
	}
	#progress-loading {
		.body {
			justify-content: flex-start;
			padding-top: 80px;
		}
	}

	#passthru-p3 {
		.title {
			font-size: 45px;
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {
	#compare-area #filtered-data > div:not(:first-child),
	#compare-area .bottom-info > div:not(:first-child) {
		border-left: unset;
	}

	#compare-area {
		min-height: 100vh;
		&.hotels {
			background: $new;
			color: #fff;
		}
		.comp-logo {
			justify-content: center;
			align-items: center;
			position: relative;
			margin-right: 12px;
		}

		.ml-i {
			margin-left: 0;
		}

		padding: 8px 0px;
		.company-item {
			background: transparent;
			box-shadow: none;
			margin-bottom: 0;
			padding: 0 18px 10px;
			.comp-logo {
				width: 170px;
				background: #fff;
				border-radius: 4px;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: 96px !important;
					height: 36px;
				}
			}
			.view-btn {
				background: $yellow;
				border-radius: 4px;
				padding: 6px 16px;
				font-weight: bold;
				font-style: normal;
				font-size: 14px;
				line-height: 24px;
				color: #000;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.c-checkbox-compare {
				display: flex;
				input:checked ~ .checkmark {
					background-color: $yellow;
					border-color: $yellow;
				}
				input:checked ~ .checkmark:after {
					border-color: $new;
				}
				.checkmark {
					border-color: #afafaf;
				}
				.form-check {
					top: 5px;
				}
			}
		}
		.c-checkbox-compare {
			position: relative !important;
			top: -2px !important  ;
			left: unset !important;
		}
		.location-info {
			.location-name {
				font-size: 24px !important;
				color: #fff;
				font-weight: 600;
			}
		}
		.start-compare-btn {
			display: block !important;
			border-radius: 16px;
			margin-top: 25px;
		}
		.headline {
			text-align: left;
			font-size: 24px;
			line-height: 32px;
			margin-bottom: 20px;
		}
		#filtered-data {
			align-items: flex-start;
			display: block;
		}
		.underscore {
			font-size: 16px !important;
		}
		.item-name {
			font-size: 12px !important;
			margin-top: 5px !important;
		}
		.item-value {
			font-size: 16px !important;
			margin-bottom: 5px !important;
		}
	}

	#hotel-images {
		top: 55px;
		position: relative;
	}
	#image-curve {
		p {
			font-size: 24px;
			line-height: 32px;
		}
	}

	#main-area {
		.title {
			margin-top: 25px;
		}
	}
	#footer-links {
		display: unset !important;
	}

	.rtl {
		.input-box .input-control .selected {
			font-size: 12px;
		}
	}

	.input-box .input-control .selected,
	.timepicker-show .selected-time,
	.basic-drop-down .d-name {
		font-size: 15px;
		line-height: 24px;
	}
	.input-items label {
		font-size: 14px;
		line-height: 24px;
	}

	.input-items {
		.form-control,
		.input-control {
			background: #fff;
		}
	}

	#passthru-p3 {
		.title {
			font-size: 35px;
		}
	}

	#mobile-input-passengers .body {
		right: 0;
		left: unset;
	}
	footer #footer-links li {
		display: block;
		border-right-width: 0px;
		border-left: 0px !important;
		margin: 16px 0;
	}
	#cookie-notice {
		padding: 6px;

		.description {
			font-size: 15px;
			line-height: 1.7;
		}

		img {
			width: 17px;
			margin: 0 5px;
		}
	}

	#credential_picker_iframe {
		bottom: 65px !important;
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
	#image-curve {
		clip-path: unset;
		height: 400px;
		align-items: unset;
		margin-top: 0;
		p {
			padding: 20px;
			padding-bottom: 0;
		}
	}

	.rtl {
		#hotel-images {
			left: 50px;
		}

		#main-area {
			.hotels-filter-form {
				.title {
					padding: 15px 0px;
					margin-bottom: 0px;
					text-align: right;
				}
			}
		}
	}

	#hotel-images {
		top: 75px;
		position: relative;
		left: -50px;

		img {
			position: relative;
			width: 200px;
			height: 130px;
		}
		& > img:first-child {
			right: -250px;
			z-index: 99;
		}

		& > img:nth-child(2) {
			top: 60px;
			right: -180px;
		}

		& > img:nth-child(3) {
			top: -50px;
			left: 53px;
		}
	}
	.hotels-container {
		background-color: $yellow !important;
		background-image: unset !important;
	}
	#parent-filter {
		border-radius: 0;
		background-color: $yellow;
		#search-button {
			background-color: $midnight;
			color: #fff;
		}
		.main-inputs {
			//display: unse/t !important;
			& > div {
				.input-box {
					margin: 0;
				}
			}
		}
	}

	.basic-drop-down .d-name,
	.basic-drop-down .d-icon i {
		color: #000;
	}
	.basic-drop-down {
		& > div {
			border-radius: 8px;
			padding: 8px;
			display: inline;
		}
	}
	.footer {
		padding-top: 50px;
		p {
			font-size: 14px;
		}
	}
	#main-content {
		#title {
			font-size: 24px;
		}
		#description {
			font-size: 18px;
			text-align: justify;
		}
	}
	#main-area {
		.hotels-filter-form {
			//#search-button-container #search-button {
			//  background-color: #fff;
			//  color: $blue;
			//}

			.title {
				padding: 15px 0px;
				margin-bottom: 0px;
				text-align: left;
				//background-color: #fff;
			}
		}
	}
	.Logo {
		width: 190px;
	}
	.select-box {
		margin: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 5px;

		margin-bottom: 5px;

		.selected-area {
			min-width: 100px;
			min-height: 35px;
		}
	}
	#family-type-options {
		// width: 100% !important;
		left: 0;
		right: 0;
		max-width: 100%;
		top: 100%;
	}
	.room-name-area {
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: space-between;

		.remove-icon-mobile {
			display: block;
		}
	}
	#mobile-input-passengers .body {
		width: calc(200% + 30px);
	}
	.cars-filter-form,
	.hotels-filter-form {
		margin-top: 0 !important;
		border-top-left-radius: 0px !important;
		border-top-right-radius: 0px !important;
	}
	#companies {
		ul {
			li {
				width: 145px;

				div img {
					width: 120px;
				}
			}
		}

		.description {
			font-size: 24px;
		}
	}
	#slogan-container .body .slogan {
		font-size: 25px !important;
	}
	#passthru-p3 {
		.title {
			font-size: 25px;
		}
		.logo {
			width: 106px !important;
		}
		.brand-redirection {
			padding: 5px;
			margin-top: 5px;
		}
		#loading-to-partner #animation .m_scroll_arrows {
			width: 12px;
			height: 12px;
		}
	}
}

@media (min-width: 1200px) {
	.top-inputs {
		padding-left: 7px;
	}
	.datetimepicker {
		max-width: 240px;
	}
	.main-inputs {
		& > div {
			padding: 0;
		}
	}
}

@media (max-width: 575px) {
	#family-type-options .room .name {
		font-weight: bold;
	}
	#family-type-options:before {
		right: 49.7%;
	}
	#family-type-options:after {
		right: 50%;
	}
	#main-area {
		.title {
			font-size: 24px !important;
			padding: 10px 0px;
			margin: 10px;
			//background-color: #fff;
		}
	}
	.btn-blue-border {
		font-size: 14px;
	}

	.rtl {
		#hotel-images {
			left: 60px;
		}
	}

	#hotel-images {
		left: -60px;
		max-width: calc(100vw - 40px);
	}
}
@media (max-width: 440px) {
	.btn-blue-border {
		min-width: 100px;
		font-size: 11px;
	}
}
@media (max-width: 380px) {
	.rtl {
		#hotel-images {
			left: 40px;
		}
	}
	#hotel-images {
		left: -40px;
		top: 20px;
		img {
			width: 150px;
			object-fit: contain;
		}
	}
}
@media (max-width: 360px) {
	.Logo {
		width: 160px;
	}
}
@media (max-width: 330px) {
	.check-in-input,
	.check-out-input {
		width: 100%;
	}
	.footer {
		padding-top: 100px;
	}
	.rtl {
		#hotel-images {
			left: 30px;
		}
	}
	#hotel-images {
		left: -30px;
		img {
			width: 150px;
			object-fit: contain;
		}
	}
}
